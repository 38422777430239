import { usePage } from "@inertiajs/react";
import axios from "axios";

export const useAxios = () => {
    const { csrfToken } = usePage().props;

    const axiosInstance = axios.create({
        headers: {
            "X-Csrf-Token": csrfToken,
        },
    });

    return axiosInstance;
};
